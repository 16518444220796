.site-footer {
    color: @secondary;
    background: #e0f5ff;

    &-top {
        border-top: 12px solid @white;
        padding: 5vw 0;

        .container {
            justify-content: center;
        }
    }

    &-bottom {
        border-top: 1px solid fade(@primary, 50%);
        padding: 20px 0;
        font-size: 14px;

        .container {
            justify-content: space-between;
        }

        .copyright {
            font-weight: 600;
        }

        a {
            text-decoration: none;

            &:hover {
                color: @primary;
            }

            &+a {
                &:before {
                    content: '|';
                    margin: 0 10px;
                    text-decoration: none;
                }
            }
        }
    }

    h2 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 0.7em;
    }

    a {
        font-weight: 600;
        color: inherit;
    }

    .container {
        align-items: center;
        gap: 5vw;
    }
    img {
        max-width: 100%;
    }
    @media (max-width: 700px) {
        .container {
            flex-direction: column;
        }
        .column-left {
            order: 2;
        }
        .column-right {
            order: 1;
        }
        img {
            max-width: 70vw;
            margin: 0 auto;
        }
    }
    @media (min-width: 700px) {
        .column-left {
            width: 40%;
            text-align: center;
            img {
                max-width: 70%;
                margin: 0 auto;
            }
        }
    }
    @media (min-width: 1200px) {
        .column-left {
            width: 20%;
            img {
                max-width: 100%;
                margin: 0 auto;
            }

        }
    }
}