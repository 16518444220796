.indicator {
    user-select: none;
    flex-grow: 1;
    // background: @white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 280px 0 280px;
    &.has-error {
        .indicator-inner {
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .indicator-title {
                order: 2;
                margin-bottom: 0;
            }
        }
    }

    .fadeIn();

    &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-title {
        font-weight: 600;
        font-size: 1rem;
        margin: 0 0 15px;

    }

    .loading {
        &-indicator {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: transparent;
            border: 4px solid fade(@primary, 20%);
            border-bottom-color: @primary;
            -webkit-animation: 2s spin linear infinite;
            animation: 2s spin linear infinite;
        }

        &-error {
            opacity: 0.3;
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi41LjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGFhZ18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDU2MCA1NjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2MCA1NjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0yODAsMEMyMDUuNywwLDEzNC41LDI5LjUsODIsODJDMjkuNSwxMzQuNSwwLDIwNS43LDAsMjgwczI5LjUsMTQ1LjUsODIsMTk4YzUyLjUsNTIuNSwxMjMuNyw4MiwxOTgsODJzMTQ1LjUtMjkuNSwxOTgtODINCgljNTIuNS01Mi41LDgyLTEyMy43LDgyLTE5OHMtMjkuNS0xNDUuNS04Mi0xOThDNDI1LjUsMjkuNSwzNTQuMywwLDI4MCwweiBNMzE1LDQyMGgtNzB2LTcwaDcwVjQyMHogTTMxNSwzMDMuM2gtNzBWMTQwaDcwVjMwMy4zeiINCgkvPg0KPC9zdmc+DQo=");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}



@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}