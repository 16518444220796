@font-face {
    font-family: 'open_sans';
    src: url('./fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans';
    src: url('./fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans';
    src: url('./fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans';
    src:
        url('./fonts/opensans/opensans-italic-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'open_sans';
    src: url('./fonts/opensans/opensans-bolditalic-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'open_sans';
    src: url('./fonts/opensans/opensans-semibolditalic-webfont.woff2') format('woff2'),
        url('./fonts/opensans/opensans-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'appstore';
    src: url('./fonts/iconfont/fonts/appstore.woff2?ij2ni8') format('woff2'),
      url('./fonts/iconfont/fonts/appstore.woff?ij2ni8') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }