.detail {
    position: relative;
    overflow: hidden;
    padding: 40px 0;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: @white;
        opacity: 0.4;
        z-index: 1;
        pointer-events: none;
    }

    .background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.4;
        background-repeat: no-repeat;
        background-size: 60vw auto;
        background-image: url(../resources/images/map-bg-right.svg);
        background-position: bottom right;
    }

    .container {
        position: relative;
        z-index: 1;
        gap: 20px;

        @media (max-width: 769px) {
            flex-direction: column;
        }

        @media (min-width: 768px) {
            gap: 40px;
        }

        .column-left {
            @media (min-width: 769px) {

                width: 20%;
                min-width: 300px;
            }

            .card {
                &-top {
                    @media (min-width: 769px) {
                        padding-top: 50px;
                    }

                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .image {
                        img {
                            width: 100%;
                            max-width: 150px;

                            @media (max-width: 769px) {
                                max-width: 40vw;
                            }
                        }
                    }
                }

                &-bottom {
                    padding-top: 20px;

                    @media (min-width: 768px) {
                        padding-top: 50px;
                    }

                    font-size: 14px;

                    @media (min-width: 480px) and (max-width: 768px) {
                        dl {
                            max-width: 45vw;
                            margin: 0 auto;
                        }
                    }
                }
            }

            .tag {
                margin-top: 30px;
                display: inline-flex;
            }

            .button {
                margin-top: 30px;
                &.update-button {
                    margin-top: 0;
                }
            }
            .status {
                margin: 20px 0 10px;
                font-weight: 600;
                font-size: 16px;
            }
            h1 {
                font-size: 20px;
                color: @primary;
                margin-bottom: 0;
            }

            p {
                margin-top: 0;
                font-weight: 600;
            }

            h2 {
                margin: 0;
                color: @primary;
            }

            dl {
                display: flex;
                flex-wrap: wrap;

                dd,
                dt {
                    margin: 0;
                    width: 50%;
                }

                dd {
                    padding-right: 15px;
                }

                dt {
                    font-weight: 600;
                    padding-left: 15px;
                }
            }

            .environment-error {
                font-size: 13px;
                font-weight: 500;
                color: red;
                padding: 5px 0;
            }
        }

        .column-right {
            flex: 1;


            h2 {
                margin: 0;
                color: @primary;
            }

            .card {}

            .screenshots {
                .slick-track>div {
                    margin: 10px;

                    img {
                        display: block;
                    }
                }

            }
        }
    }

    .screenshots {
        .slick-arrow {
            background: @primary;
            width: 40px;
            height: 40px;
            color: @white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: @global-transition;
            border-radius: 4px;
            font-size: 16px;

            &.slick-disabled {
                display: none;
                opacity: 0;
                visibility: hidden;
            }
        }

        .slick-prev {
            left: -30px;
            transform: translateX(-50%) translateY(-50%);

            &:before {
                content: '';
            }
        }

        .slick-next {
            right: -30px;
            transform: translateX(50%) translateY(-50%);

            &:before {
                content: '';
            }
        }

        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
        }
    }

    .card {
        padding: 30px;
    }

    .backbutton {
        margin-bottom: 40px;
    }

    .generate-style-detail(@style-color) {
        .install-button {
            background-color: @style-color;

            &:hover {
                background-color: lighten(@style-color, 8%);
                border-color: lighten(@style-color, 8%);
            }
        }

        .update-button {
            background-color: @style-color;

            &:hover {
                background-color: lighten(@style-color, 8%);
                border-color: lighten(@style-color, 8%);
            }
        }

        .status {
            color: @style-color;
        }

        .installed {
            background-color: rgb(47, 143, 55);
            cursor: auto;
        }

        .tag {
            border-color: @style-color;
            color: @style-color;
        }

        .backbutton {
            color: @style-color;
        }

        .screenshots {
            .slick-arrow {
                background: @style-color;

                &:hover {
                    background: lighten(@style-color, 8%);
                }

                &:active {
                    background: darken(@style-color, 10%);
                    border-color: darken(@style-color, 10%);
                }
            }
        }

        .column-left {

            h1,
            h2 {
                color: @style-color;
            }
        }

        .column-right {
            h2 {
                margin: 0;
                color: @style-color;
            }
        }
    }


    &.style-apps {
        background: @background-gradient-apps;
        color: @color-apps-darkest;
        .generate-style-detail(@color-apps);

        .backbutton {
            color: @white;
        }
    }

    &.style-extensions {
        background: @background-gradient-extensions;
        color: @color-extensions-darkest;
        @style-color: @color-extensions;
        .generate-style-detail(@color-extensions);
    }

    &.style-blocks {
        background: @background-gradient-blocks;
        color: @color-blocks-darkest;
        .generate-style-detail(@color-blocks);
    }
}