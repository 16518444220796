@gutter-width: 30px;
@global-font: open_sans, Arial, Helvetica, sans-serif;
@global-fontsize: 16px;
@button-fontsize: 16px;
@primary: #007BB3;
@secondary: #004666;
@mediumblue: #005B80;
@darkblue: #004967;
@white: #ffffff;
@black: #000000;
@grey: #686868;
@grey-1: #c1c1c1;
@gray-2: #a1a1a1;
@dark-grey: #545454;
@light-grey: #e9e9e9;
@lighter-grey: #F5F5F5;
@global-transition: all .2s ease-in-out;

@color-apps: #007BB3;
@color-apps-darker: #01618d;
@color-apps-darkest: #004666;
@background-gradient-apps: linear-gradient(90deg, #009be1 0%, #004e71 100%);
@color-extensions: #5C9B0D;
@color-extensions-darker: #427700;
@color-extensions-darkest: #2A4B00;
@background-gradient-extensions: linear-gradient(90deg, #b5db85 0%, #dceec5 100%);
@color-blocks: #FF7700;
@color-blocks-darker: #CE6000;
@color-blocks-darkest: #823D00;
@background-gradient-blocks: linear-gradient(90deg, #feebce 0%, #ffb64b 100%);