*,
*::before,
*::after {
    box-sizing: border-box;
}
& * {
    min-height: 0;
    min-width: 0;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-size: 16px;

    line-height: 1.6;
    font-family: @global-font;

}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

.semibold {

    font-weight: 600;
}

.bold {

    font-weight: 700;
}

svg.icon {
    fill: currentColor;
}

.backbutton {
    color: @white;
    font-weight: 600;
    font-size: 22px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: @global-transition;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}
.indicator {
    position: relative;
    z-index: 1;
}
.fadeIn(@duration: 200ms, @delay: 10ms) {
    animation: show @duration @delay cubic-bezier(.25,.1,.25,1) forwards;
    opacity: 0;
}
@keyframes show {
    100% {
        opacity: 1;
    }
}
.flex {
    display: flex;
}
.justify-content-end {
    justify-content: flex-end;
}