.header {
    width: 100%;
    height: 75px;

    img {
        display: block;
    }

    .h1 {
        font-size: 24px;
        @media (min-width: 480px) {
        font-size: 26px;
        }
        font-weight: 600;
    }
    .environments {
        @media (max-width: 480px) {
            display: none;
        }
        display: flex;
        gap: 10px;
        align-items: center;
        input {
            border: 1px solid fade(white, 40%);
            background: fade(white, 10%);
            border-radius: 4px;
            padding: 7px;
            color: white;
            outline: none;
            transition: @global-transition;
            &:hover {
                border-color: fade(white, 60%);
                background: fade(white, 20%);
            }
            &:focus {
                border-color: white;
                background: fade(white, 20%);
            }
            &::placeholder {
                color:fade(white, 50%)
            }
            &[aria-invalid=true] {
                border-color: firebrick;
            }
        }
        .inputWrap {
            display: flex;
            flex-direction: column;
            position: relative;
            .error {
                color: white;
                font-size: 13px;
                font-weight: 600;
                position: absolute;
                left: 5px;
                bottom: 0;
                transform: translateY(100%);
            }
        }
        .infoWrap {
            position: relative;
            display: flex;
            align-items: center;
            .info {
                cursor: pointer;
                color: @white;
                border: 1px solid fade(white, 40%);
                background: fade(white, 10%);
                transition: @global-transition;
                padding: 0;
                font-size: 15px;
                height: 23px;
                display: flex;
                align-items: center;
                border-radius: 3px;
                padding: 0 7px;
                &:hover, &:active {
                    border-color: fade(white, 60%);
                    background: fade(white, 20%);
                }
                &-tooltip {
                    width: max-content;
                    max-width: 250px;
                    color: @secondary;
                    top: 100%;
                    background: @white;
                    margin-top: 15px;
                    padding: 0px 12px;
                    font-size: 13px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    border: 1px solid rgba(0, 0, 0, .16);
                    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
                    visibility: hidden;
                    opacity: 1;
                    transition: @global-transition;
                    &:before,
                    &:after {
                        pointer-events: none;
                        content: " ";
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
            
                    &:after {
                        top: -14px;

                        border: 7px solid transparent;
                        border-bottom-color: #F9F9F9;
                    }
            
                    &:before {
                        top: -16px;
            
                        border: 8px solid transparent;
                        border-bottom-color: #cdcdcd;
                    }
                    p {
                        margin: 8px 0;
                    }
                    span {
                        font-weight: 700;
                        color: @primary;
                    }
                }
                &:hover {
                    & + .info-tooltip {
                        visibility: visible;
                        opacity: 1;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .inner {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        height: inherit;
        background: linear-gradient(90deg, #0081bb 0%, #00415e 100%);
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        color: @white;
        z-index: 4;

    }
    .logo {
        @media (max-width: 480px) {
            width: 150px;
        }
        img {
            max-width: 100%;
        }
    }
    .container {
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: none;
    }

    .column-right {
        display: flex;
        gap: 20px;

        @media (min-width: 400px) {
            gap: 50px;

        }

        align-items: center;
    }
    div.icon {
        width: 40px;
        height: 40px;

        position: relative;
        .icon {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .profile-menu {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        a {
            cursor: pointer;
        }

        .dropdown-menu {
            position: absolute;
            display: none;
            top: 50px;
            right: 0;
            border-radius: 10px;
            font-size: 14px;

            color: @secondary;
            background: rgba(255, 255, 255, 0.9);
            border: 1px solid rgba(60, 60, 60, 0.18);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.32);

            &.show {
                display: block;
            }

            a {
                color: inherit;
                text-decoration: none;
            }

            &-items {


                display: flex;
                flex-direction: column;

                a {
                    padding: 10px 20px;
                    line-height: normal;
                }
            }
        }
        .profile-image {
            border-radius: 100%;
            width: 48px;
            height: 48px;
            flex: none;
            cursor: pointer;
            border: 2px solid #fff;
            box-shadow: 0 0 6px rgb(0 0 0 / 16%);
            overflow: hidden;
            &:hover {
                .profile-picture {
                    transform: scale(1.07);
                }
            }
        }
        .profile-picture {
            width: 44px;
            height: 44px;
            object-fit: cover;
            transition: @global-transition;
        }

        .username {
            white-space: nowrap;
            font-weight: 600;
        }
    }

    button.sign-in {
        width: 40px;
        height: 40px;
        font-size: 40px;
        color: @white;
        border: none;
        background: none;
        cursor: pointer;
        transition: @global-transition;

        &:hover {
            color: @primary;
        }
    }
}