.container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-right: 25px;
    padding-left: 25px;
    transition: max-width .2s ease-in-out;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1580px;
    }
}