

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'appstore' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left:before {
  content: "\e900";
}

.icon-chevron-up:before {
  content: "\e901";
}

.icon-chevron-down:before {
  content: "\e902";
}

.icon-chevron-right:before {
  content: "\e903";
}
.icon-user-icon:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e905";
}
.icon-cross:before {
  content: "\e906";
}
.icon-tick:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e908";
}