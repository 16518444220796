// Mixin for generating colored buttons
.generate-button(@color, @background) {
    background: @background;
    border: 1px solid @background;

    &:not(.disabled):not(:disabled):hover {
        background: lighten(@background, 8%);
        color: @color;
        border-color: lighten(@background, 8%);
    }

    &:not(.disabled):not(:disabled):active {
        background: darken(@background, 10%);
        border-color: darken(@background, 10%);
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        color: fade(@color, 50%);
    }
}

button {
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }

    &:focus-visible,
    &.focus-visible {
        outline: 2px solid black;
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        outline: none;
        cursor: default;

        &:focus {
            outline: none;
        }
    }

}

.button {
    font-size: @button-fontsize;
    line-height: 1.8em;
    font-family: @global-font;
    font-weight: 600;

    margin: 0;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    text-decoration: none;
    color: @white;
    background: @primary;
    padding: 10px 20px;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.21);
    
    &-primary {
        .generate-button(@white, @primary);
    }

    &-secondary {
        .generate-button(@white, @secondary);
    }

    &-apps {
        .generate-button(@white, @color-apps);
    }

    &-extensions {
        .generate-button(@white, @color-extensions);
    }

    &-blocks {
        .generate-button(@white, @color-blocks);
    }

    &-blank {
        background: none;
        border-color: transparent;
    }

    &-small {
        padding: 0.6rem 0.8rem;
        font-size: 14px;
    }

    &-icon {
        gap: 10px;

        .icon {
            flex: none;
        }
    }
}