.card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(60, 60, 60, 0.18);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.32);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.tag {
    color: #004666;
    font-size: 14px;
    border: 1px solid #007BB3;
    border-radius: 6px;
    background: @white;
    flex: none;
    padding: 3px 20px;
    display: flex;
    gap: 5px;
    align-items: center;

    &.has-icon {
        padding: 3px 10px;

        .dot {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: currentColor;
        }

        .icon-tick {}
    }
}

.status {
    font-size: 12px;
    display: flex;
    gap: 5px;
    align-items: center;

    .dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: currentColor;
    }
}

.cards-block {
    padding: 40px 0;

    @media (min-width: 1200px) {
        padding: 80px 0;
    }

    position: relative;
    overflow: hidden;

    .container {
        gap: 25px;
        flex-direction: column;

        @media (min-width: 1200px) {
            flex-direction: row;
            gap: 5vw;
        }
    }

    .column-left {
        @media (min-width: 1200px) {
            width: 33%;
            margin-right: 2vw;
        }

        h2 {
            font-size: 30px;
            font-size: clamp(18px, 3vw, 30px);

            margin: 0 0 0.5em;

            @media (min-width: 1200px) {
                margin-top: 1em;
            }
        }

        p {
            margin-top: 0;
        }
    }

    .column-right {
        position: relative;
        @media (min-width: 1200px) {
            width: 66%;
            flex-grow: 1;
            justify-content: flex-start;
        }
        .indicator {
            position: unset;
            padding: 0;
            grid-column-end: 4;
            grid-column-start: 1;
            pointer-events: none;
            align-items: flex-start;
            .loading-error {
                flex: none;
            }
            @media (min-width: 1200px) {
                position: absolute;
                padding-left: 0;
                padding-right: 0;
                .indicator-inner {
                    margin-top: 130px;

                }
            }
        }
    }

    .cards {
        display: flex;
        gap: 25px;
        flex-wrap: wrap;
        flex-direction: column;

        @media (min-width: 700px) {
            display: grid;
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 1200px) {
            gap: 35px;
        }

        &-list {
            display: flex;
            gap: 10px;
            flex-direction: column !important;
            margin: 20px 0 0;

            .card {
                flex: none;
                flex-direction: row;
                padding: 10px 20px;

                &-top {
                    padding-top: 0;
                    flex-direction: row;

                    .image {
                        padding: 0;
                        margin-right: 20px;
                        height: 60px;
                        width: 100px;
                    }

                    p {
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 13px;
                    }

                    p:empty {
                        display: none;
                    }

                    h3 {
                        font-size: 16px;
                        margin: 0;
                    }

                    p,
                    h3 {
                        text-align: left;
                    }
                }

                &-bottom {
                    padding-top: 0;
                    justify-content: center;

                    .tag {
                        align-self: flex-end;
                    }
                }
            }
        }
    }

    .card {
        text-decoration: none;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        width: 100%;

        @media (min-width: 700px) {
            flex-grow: 1;
            flex-basis: 0;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.40);


            img {
                transform: scale(1.05);
            }

        }

        &-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media (min-width: 700px) {
                padding-top: 40px;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 25px;
                margin-bottom: 0;
                line-height: 1.2em;
                text-align: center;
                color: #007BB3
            }

            p {
                text-align: center;
                margin: 5px 0 1em;
                color: #004666;
                font-weight: 600;
            }
        }

        &-bottom {
            display: flex;
            padding-top: 20px;
            flex-direction: column;

            .tag {
                align-self: flex-start;
            }

            .status {
                margin: 5px 0 -10px;
            }
        }



        img {
            transition: @global-transition;
        }

        .image {
            height: 80px;
            padding: 0 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: @white;
        opacity: 0.4;
        z-index: 1;
        pointer-events: none;
    }

    .background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.4;
        background-repeat: no-repeat;
        background-size: 60vw auto;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    &.style-apps {
        background: @background-gradient-apps;

        .cards {

            flex-direction: column-reverse;

            @media (min-width: 700px) {

                .card:nth-child(1) {
                    grid-column-start: 3;
                    grid-row-start: 1;
                }

                .card:nth-child(2) {
                    grid-column-start: 1;
                    grid-row-start: 1;
                }

                .card:nth-child(3) {
                    grid-column-start: 2;
                    grid-row-start: 1;
                }
            }

        }

        &.cards-block-list {
            .cards {
                @media (min-width: 700px) {

                    .card:nth-child(1) {
                        grid-column-start: 3;
                        grid-row-start: 1;
                    }

                    .card:nth-child(2) {
                        grid-column-start: 2;
                        grid-row-start: 1;
                    }

                    .card:nth-child(3) {
                        grid-column-start: 1;
                        grid-row-start: 1;
                    }
                }
            }
        }

        .background {
            background-image: url(../resources/images/map-bg-right.svg);
            background-position: bottom right;
        }

        .column-left {
            color: @white;
        }

        .card-top {
            p {
                color: @color-apps-darkest;
            }

            h3 {
                color: @color-apps;
            }
        }

        .tag {
            border-color: @color-apps;
            color: @color-apps;
        }

        .status {
            color: @color-apps;
        }
        .indicator {
            color: @white;
        }
    }

    &.style-extensions {
        background: @background-gradient-extensions;

        .background {
            background-image: url(../resources/images/map-bg-left.svg);
            background-position: bottom left;
        }

        border: 12px solid #fff;
        border-left: none;
        border-right: none;

        .column-left {
            color: @color-extensions-darker;
        }

        .card-top {
            p {
                color: @color-extensions-darkest;
            }

            h3 {
                color: @color-extensions;
            }
        }

        .tag {
            border-color: @color-extensions;
            color: darken(@color-extensions, 20%);
        }

        .status {
            color: @color-extensions;
        }
        .indicator {
            color: @color-extensions-darker;
        }
    }

    &.style-blocks {
        background: @background-gradient-blocks;

        .background {
            background-image: url(../resources/images/map-bg-right.svg);
            background-position: bottom right;

        }

        .column-left {
            color: @color-blocks-darker;
        }

        .card-top {
            p {
                color: @color-blocks-darkest;
            }

            h3 {
                color: @color-blocks;
                margin-bottom: 20px;
            }
        }

        .tag {
            border-color: @color-blocks;
            color: darken(@color-blocks, 20%);
        }

        .status {
            color: @color-blocks;
        }
        .indicator {
            color: @color-blocks-darker;
        }
        .card .image {
            width: 80px;
            border-radius: 10px;
            border: 1px solid @color-blocks;
            transition: @global-transition;
            padding: 0;

            img {
                object-fit: cover;
            }
        }
    }

    &-button {
        padding: 40px 0 0;
        display: flex;
        justify-content: flex-end;
    }

    &-list {
        padding-top: 40px;

        .intro {
            padding-bottom: 3vw;

            h2 {
                margin-top: 0;
            }
        }

        .cards {
            width: 100%;
        }

        .backbutton {
            color: inherit;
        }

        .backbutton {
            margin-bottom: 40px;
        }

        &.style-apps {
            color: @color-apps-darkest;

            .backbutton {
                color: @white;
            }
        }

        &.style-extensions {
            color: @color-extensions-darkest;

            border: none;

            .backbutton {
                color: @color-extensions;
            }
        }

        &.style-blocks {
            color: @color-blocks-darkest;

            .backbutton {
                color: @color-blocks;
            }
        }

    }
}