.user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .profileName {
        color: @secondary;
        text-align: right;
        white-space: nowrap;

        @media (max-width: 1024px) {
            font-size: 13px;
        }

        @media (max-width: 1200px) {
            font-size: 14px;
        }
    }

    .profileImg {
        border-radius: 100%;
        width: 48px;
        height: 48px;

        @media (max-width: 769px) {
            width: 42px;
            height: 42px;
        }

        flex: none;
        cursor: pointer;
        border: 2px solid @white;
        box-shadow: 0 0 6px rgba(0, 0, 0, .16);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .currentUserWindow {
        .fadeIn();
        font-weight: 400;
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1;
        color: @black;
        background: white;
        border: 1px solid rgba(0, 0, 0, .16);
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
        line-height: normal;

        &:before,
        &:after {
            pointer-events: none;
            content: " ";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            right: 15px;
        }

        &:after {
            top: -14px;
            right: 16px;
            border: 7px solid transparent;
            border-bottom-color: #F9F9F9;
        }

        &:before {
            top: -16px;
            border: 8px solid transparent;
            border-bottom-color: #cdcdcd;
        }

        .top {
            padding: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, .16);
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            justify-content: space-between;
            background: #F9F9F9;
            width: 100%;
        }

        .user-info {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            justify-content: space-between;
        }

        .user-name {
            font-weight: 600;
            font-size: 16px;
            white-space: nowrap;
        }

        .user-email {
            font-size: 11px;
            white-space: nowrap;
        }

        .user-details {
            text-align: left;
            padding-right: 20px;
        }

        .user-image {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            object-fit: cover;
            border: 2px solid @white;
            box-shadow: 0 0 6px rgba(0, 0, 0, .16);
            overflow: hidden;
        }

        .user-links {
            display: flex;
            gap: 3px;
            flex-direction: column;
            padding: 7px 0 0;
            align-items: flex-start;

            button {
                font-size: 13px;
                font-family: @global-font;
                cursor: pointer;
                border: none;
                color: @secondary;
                padding: 0;
                white-space: nowrap;
                background: transparent;
                font-size: 11px;
                transition: @global-transition;

                &:hover {
                    color: darken(@secondary, 20%);
                    text-decoration: underline;
                }
            }
        }

        .bottom {
            width: 100%;
            display: flex;
            flex-direction: column;


            a.link {
                padding: 10px 20px;

                &:first-child {
                    border-top: none;
                    margin-top: 8px;
                }

                width: 100%;
                white-space: nowrap;
                text-align: left;
                font-family: inherit;
                font-size: 14px;
                background: @white;
                font-family: @global-font;
                text-decoration: none;
                cursor: pointer;
                border: none;
                color: @secondary;
                padding: 10px 20px;
                margin-bottom: 0;
                &:hover {
                    background: darken(#E4ECF3, 10%);
                }
                &.logout {
                    margin-top: 8px;
                    border-top: 1px solid #d0d1d1;
                    background: #f9f9f9;
                    &:hover {
                        background: darken(#E4ECF3, 10%);
                    }
                }

            }
        }
    }
}