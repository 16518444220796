.intro-block {
    color: @white;
    background: linear-gradient(90deg, rgba(0, 155, 225, 1) 0%, rgba(0, 78, 113, 1) 100%);
    position: relative;
    overflow: hidden;

    .decoration {
        width: 100vw;
        height: 50vw;
        position: absolute;
        top: -50vw;
        transform: rotate(30deg);
        transform-origin: left bottom;
        background: linear-gradient(90deg, #19a0dc 0%, #0076ab 100%);

        left: 30%;

        @media (min-width: 768px) {
            left: 50%;
        }
    }

    .container {
        position: relative;
        justify-content: space-between;
        // margin-left: calc((100% - 1680px) / 2);
        // margin-right: 0;
        // max-width: none;
        align-items: center;
        flex-direction: column;
        padding-bottom: 40px;

        .column-left {
            order: 2;
        }

        .column-right {
            order: 1;
        }

        @media (min-width: 768px) {
            padding-bottom: 0;
            flex-direction: row;

            .column-left {
                width: 55%;
                order: 1;
            }

            .column-right {
                width: 60%;
                order: 2;
            }
        }

        @media (min-width: 1200px) {
            .column-left {
                width: 50%;
            }

            .column-right {
                width: 50%;
            }
        }

        .column-right {

            img {
                max-width: 100%;
                display: block;
            }
        }
    }

    h1 {
        font-size: 35px;
        font-size: clamp(26px, 3vw, 35px);
        line-height: 1.2;
    }
}