.modal {
    font-family: @global-font;
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    &-backdrop {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: fade(@gray-2, 20%);
    }
    &-window {
        width: 100%;
        height: auto;
        max-height: 80vh;
        max-width: 600px;
        position: relative;

        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        margin: 25px;
    }
    &-title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
    }
    &-header {
        color: @white;
        background: @primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        height: 35px;
        border-radius: 8px 8px 0 0;
        user-select: none;
    }
    &-close {
        cursor: pointer;
        background: none;
        border: none;
        display: flex;
        width: 35px;
        height: 35px;
        font-size: 12px;
        transform: translateX(20px);
        align-items: center;
        justify-content: center;
        transition: @global-transition;
        color: @white;
        &:hover {
            opacity: 0.7;
        }
    }
    &-body {
        background: @white;
        color: @secondary;
        padding: 20px;
        flex: 1;
        overflow: hidden;
        position: relative;
        border-radius: 0 0 8px 8px;
        & > p:first-child {
            margin-top: 0;
        }
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        padding-top: 20px;
        &.align-center {
            justify-content: center;
        }
    }
    .indicator {
        position: unset;
        padding: 0;
        &-inner {
            flex-direction: row;
            gap: 10px;
            justify-content: center;
        }
        &-title {
            margin: 0;
        }
    }
}